<template>
  <v-card>
    <v-toolbar
      dark
      color="primary"
      dense
      class="mb-5"
    >
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Promoção</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form ref="form">
      <v-row
        justify="center"
        no-gutters
      >
        <v-col cols="10">
          <v-select
            v-model="Promotions.city"
            :items="unityCities"
            label="Cidade *"
            :rules="$rules.required"
            required
            outlined
          />
        </v-col>
        <v-col cols="10">
          <v-text-field
            v-model="Promotions.nome"
            label="Nome do promovido *"
            :rules="$rules.required"
            required
            outlined
          />
        </v-col>
        <v-col cols="10">
          <v-file-input
            v-model="imagesUpload"
            outlined
            label="Clique para adicionar imagens do tamanho mínimo de 753x451 *"
            prepend-icon="mdi-camera"
            multiple
            required
            :rules="imageAlreadyExists ? [] : $rules.required"
            accept="image/jpeg, image/png"
            @change="onFilesSelected"
          />
        </v-col>
      </v-row>
    </v-form>
    <div>
      <gallery
        :images="imagesPreview"
        :rules="$rules.required"
        @image-deleted="onDeleteImage"
      />
    </div>

    <div class="d-flex justify-center my-5">
      <v-btn
        color="secondary"
        class="mr-3"
        @click="close"
      >
        Cancelar
      </v-btn>
      <v-btn
        v-if="loading === true"
        color="primary"
        loading
        @click="submit"
      />
      <v-btn
        v-else
        color="primary"
        @click="submit"
      >
        Salvar
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { createPhoto } from '@/services/photos-service';
import PromotionsService from '../../../services/promotions.service';
import UnitCityService from '../../../services/units-city.service';

export default {
  name: 'PromotionsForm',
  props: {
    populateWith: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imagesPreview: [],
      imagesUpload: [],
      imagesDeleted: [],
      images: [],
      unityCities: [],
      Promotions: {
        id: 0 ,
        autor: '',
        city: '',
        createdAt: '',
        image: '',
        nome: '',
        status: '',
      },
      loading: false,
    };
  },
  computed: {
    imageAlreadyExists() {
      return Boolean(this.Promotions.image) || Boolean(this.imagesUpload);
    },
  },
  async created() {
    const data = await UnitCityService.findAll();
    this.unityCities = data.map((city) => city.city);
    this.unityCities = this.unityCities.sort();
    if (this.populateWith.id) {
      this.Promotions = this.populateWith;
      this.imagesPreview = [this.Promotions?.image];
    }
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    },

    onFilesSelected(files) {
      if (files.lenght > 0) {
        this.imagesPreview = [URL.createObjectURL(files[0])];
        const file = files[0];
        this.imagesUpload = file;
      }
    },

    onDeleteImage() {
      this.imagesUpload = '';
      this.imagesPreview = [];
    },

    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          if (this.imagesUpload[0]) {
            const photos = await createPhoto([this.imagesUpload[0]]);
            this.Promotions = {
              ...this.Promotions,
              image: photos[0].link,
            };
          }
          if (this.populateWith.id) {
            // eslint-disable-next-line max-len
            await PromotionsService.createPromotion(this.Promotions);
            this.$emit('promotions-edited', this.Promotions);
          } else {
            this.Promotions.status = 1;
            await PromotionsService.createPromotion(this.Promotions);
            this.$emit('promotions-added', {
              ...this.Promotions,
              createdAt: new Date(),
            });
          }
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
          this.close();
          this.$toast.success('Promoção cadastrada com sucesso');
          this.loading = false;
        } catch (e) {
          this.$handleHttpError(e);
          this.loading = false;
        }
      } else {
        this.$toast.error('Verifique os campos e tente novamente');
        this.loading = false;
      }
    },
  },
};
</script>
